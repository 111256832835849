<template>
  <b-card>
    <b-card-body class="container-fluid">
      <b-row>
        <b-col
          lg="4"
          md="6"
          sm="12"
          xs="12"
          class="my-50"
        >
          <b-media no-body>
            <b-media-aside>
              <b-avatar
                rounded
                size="42"
                variant="light-info"
              >
                <feather-icon
                  :icon="avatarIcon"
                  size="18"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="transaction-title">
                {{ title }}
              </h6>
              <small>{{ subtitle }}</small>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col
          lg="8"
          md="6"
          sm="12"
          xs="12"
          class="my-50"
        >
          <div
            class="font-weight-bolder mr-2 width-30"
          >
            <b-row>
              <b-col
                v-if="!!this.$slots"
                class="justify-content-end"
              >
                <slot class="mx-1" />
              </b-col>
              <b-col
                class="justify-content-end mr-1 my-auto"
                cols="1"
              >
                <b-button
                  variant="outline-primary"
                  class="btn-icon ml-auto"
                  @click.prevent="$emit('export')"
                >
                  <feather-icon
                    icon="ExternalLinkIcon"
                  />
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>
<script>
import {
  BCard, BMedia, BMediaAside, BAvatar, BMediaBody, BRow, BCardBody, BButton, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BRow,
    BCardBody,
    BButton,
    BCol,
  },
  props: {
    title: String,
    subtitle: String,
    avatarIcon: String,
  },
}
</script>
