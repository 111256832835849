const SYMBOLS = [
  'AAPL',
  'AUDCAD',
  'AUDCHF',
  'AUDJPY',
  'AUDNZD',
  'AUDUSD',
  'BTCUSD',
  'CADCHF',
  'CADJPY',
  'CHFJPY',
  'DASHUSD',
  'ETHUSD',
  'EU50',
  'EURAUD',
  'EURCAD',
  'EURCHF',
  'EURCZK',
  'EURGBP',
  'EURJPY',
  'EURNOK',
  'EURNZD',
  'EURPLN',
  'EURUSD',
  'GBPAUD',
  'GBPCAD',
  'GBPCHF',
  'GBPJPY',
  'GBPNZD',
  'GBPUSD',
  'GER40',
  'LTCUSD',
  'FB',
  'META',
  'NFLX',
  'NZDCAD',
  'NZDCHF',
  'NZDJPY',
  'NZDUSD',
  'TSLA',
  'US100',
  'US30',
  'US500',
  'USDCAD',
  'USDCHF',
  'USDCZK',
  'USDHKD',
  'USDHUF',
  'USDJPY',
  'USDMXN',
  'USDNOK',
  'USDPLN',
  'USDSEK',
  'USDZAR',
  'WTI',
  'XAGUSD',
  'XAUUSD',
  'XRPUSD',
]

export { SYMBOLS }
