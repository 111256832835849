<template>
  <b-overlay :show="loading">
    <b-row>
      <!--    Export E8 users -->
      <b-col lg="4">
        <export-card
          class="p-1"
          title="Export users"
          subtitle="File contains name, email and country of users"
          avatar-icon="UserIcon"
          @export="onExportUsers"
        >
          <b-form-group
            label="Select country"
          >
            <country-select v-model="exportUsers.country" />
          </b-form-group>
        </export-card>
      </b-col>
      <!--    Export users by parameters -->
      <b-col lg="8">
        <export-card
          title="Export user by advance specs"
          subtitle="Export users by orders count, account type and status"
          avatar-icon="TagIcon"
          @export="onExportUsersByParams"
        >
          <div class="d-flex flex-wrap justify-content-start">
            <b-form-group class="m-1 flex-fill">
              <label>Min order count</label>
              <b-form-input
                v-model.number="exportUsersForm.minOrdersCount"
                type="number"
                min="0"
              />
            </b-form-group>
            <b-form-group class="m-1 flex-fill">
              <label>Max order count</label>
              <b-form-input
                v-model.number="exportUsersForm.maxOrdersCount"
                type="number"
                min="0"
              />
            </b-form-group>
            <b-form-group class="m-1 flex-fill">
              <label>Account types</label>
              <v-select
                v-model="exportUsersForm.accountTypes"
                :options="accountTypes"
                label="label"
                multiple
                :reduce="item => item.id"
              />
            </b-form-group>
            <b-form-group class="m-1 flex-fill">
              <label>Account states</label>
              <v-select
                v-model="exportUsersForm.states"
                :options="states"
                label="label"
                multiple
                :reduce="item => item.id"
              />
            </b-form-group>
            <b-form-group class="m-1 flex-fill">
              <label>Purchased in:</label>
              <flat-pickr
                v-model="purchaseRange"
                class="form-control"
                :config="{ wrap: true, mode: 'range', dateFormat: 'Y-m-d'}"
              />
            </b-form-group>
            <b-form-group class="m-1 flex-fill">
              <label>Registered in:</label>
              <flat-pickr
                v-model="registeredRange"
                class="form-control"
                :config="{ wrap: true, mode: 'range', dateFormat: 'Y-m-d'}"
              />
            </b-form-group>
          </div>
        </export-card>
      </b-col>
      <!--   Export discount usage -->
      <b-col lg="6">
        <export-card
          title="Export discount usage"
          subtitle="Calculate usage/sales for selected discount "
          avatar-icon="TagIcon"
          @export="onExportDiscount"
        >
          <b-form-group
            label="Select discount"
          >
            <custom-select
              v-model="selectedDiscount"
              class="mr-1"
              module-name="discountsList"
              label="name"
              placeholder="Select discount"
            />
          </b-form-group>
        </export-card>
      </b-col>

      <!--   Export Trading history -->
      <b-col lg="6">
        <export-card
          title="Export trading history"
          avatar-icon="BarChartIcon"
          @export="onExportTradingHistory"
        >
          <div class="d-flex flex-wrap justify-content-start">
            <b-form-group
              class="m-1 flex-fill"
            >
              <entity-select
                v-model="exportTradingHistoryForm.user"
                :filter="userFilter"
              />
            </b-form-group>
            <b-form-group
              label="Select countries:"
              class="m-1 flex-fill"
            >
              <country-select
                v-model="exportTradingHistoryForm.countries"
                multiple
              />
            </b-form-group>
            <b-form-group
              class="m-1 flex-fill"
            >
              <label>Account types</label>
              <v-select
                v-model="exportTradingHistoryForm.accountTypes"
                :options="accountTypes"
                label="label"
                multiple
                :reduce="item => item.id"
              />
            </b-form-group>
            <b-form-group
              label="Open in:"
              class="m-1 flex-fill"
            >
              <flat-pickr
                v-model="exportTradingHistoryForm.range"
                class="form-control"
                :config="{ wrap: true, mode: 'range', dateFormat: 'Y-m-d'}"
              />
            </b-form-group>
            <b-form-group
              label="Symbols"
              class="m-1 flex-fill"
            >
              <v-select
                v-model="exportTradingHistoryForm.symbols"
                :options="SYMBOLS"
                multiple
              />
            </b-form-group>
          </div>
        </export-card>
      </b-col>

      <!--   Export sales by products -->
      <b-col lg="6">
        <export-card
          title="Export sales/payouts/account stats by product"
          subtitle=" "
          avatar-icon="TagIcon"
          @export="onExportStatsByProducts"
        >
          <b-form-group class="m-1 flex-fill">
            <label>Range in:</label>
            <flat-pickr
              v-model="salesByProductRange"
              class="form-control"
              :config="{ wrap: true, mode: 'range', dateFormat: 'Y-m-d'}"
            />
          </b-form-group>
        </export-card>
      </b-col>

      <!--      Export top payouts -->
      <b-col lg="6">
        <export-card
          title="Export top payouts"
          subtitle="Export top payouts by creation date or country"
          avatar-icon="DollarSignIcon"
          @export="onExportTopPayouts"
        >
          <b-form-group class="flex-fill">
            <label>Range in:</label>
            <flat-pickr
              v-model="exportTopPayoutsForm.range"
              class="form-control"
              :config="{ wrap: true, mode: 'range', dateFormat: 'Y-m-d'}"
            />
          </b-form-group>
          <b-form-group
            label="Select country"
          >
            <country-select v-model="exportTopPayoutsForm.country" />
          </b-form-group>
          <b-form-group
            label="Select country"
          >
            <b-form-input
              v-model.number="exportTopPayoutsForm.limit"
              type="number"
            />
          </b-form-group>
        </export-card>
      </b-col>

    </b-row>
  </b-overlay>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BOverlay,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ExportAPI from '@/api/export'
import ExportCard from '@/views/tools/exports/components/ExportCard.vue'
import CustomSelect from '@/components/ui/forms/CustomSelect.vue'
import { ACCOUNTS_SHORT } from '@/enums/accounts'
import CountrySelect from '@/components/ui/forms/CountrySelect.vue'
import { SYMBOLS } from '@/enums/symbols'
import EntitySelect from '@/components/ui/gqlforms/EntitySelect.vue'

export default {
  components: {
    EntitySelect,
    CountrySelect,
    ExportCard,
    CustomSelect,
    BFormGroup,
    BRow,
    BCol,
    BFormInput,
    BOverlay,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      SYMBOLS,
      loading: false,
      states: [
        {
          id: 'active',
          label: 'Active',
        },
        {
          id: 'failed',
          label: 'Failed',
        },
        {
          id: 'completed',
          label: 'Completed',
        },
      ],
      lastDaysOptions: [31, 60, 90],
      selectedDiscount: null,
      exportUsers: { country: null },
      purchaseRange: null,
      registeredRange: null,
      exportUsersForm: {
        minOrdersCount: null,
        maxOrdersCount: null,
        purchaseFrom: null,
        purchaseTo: null,
        registeredFrom: null,
        registeredTo: null,
        accountTypes: [],
        states: [],
      },
      exportTradingHistoryForm: {
        user: null,
        countries: [],
        from: null,
        to: null,
        range: null,
        symbols: [],
        accountTypes: [],
      },
      salesByProductRange: null,
      salesByProductForm: {
        from: null,
        to: null,
      },
      userFilter: {
        name: 'user',
        label: 'User',
        filterable: {
          type: 'entity', queryType: 'users', label: 'email', search: 'email', value: 'id',
        },
      },
      exportTopPayoutsForm: {
        country: null,
        from: null,
        to: null,
        range: null,
        limit: 30,
      },
    }
  },
  computed: {
    accountTypes() {
      const keys = Object.keys(ACCOUNTS_SHORT)

      return keys.map(key => ({ id: key, label: ACCOUNTS_SHORT[key] }))
    },
  },
  methods: {
    onExportUsers() {
      this.loading = true
      ExportAPI.exportUsers(this.exportUsers.country).then(() => {
        this.loading = false
      })
    },
    onExportDiscount() {
      if (!this.selectedDiscount) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Unable to proceed',
            icon: 'WarningIcon',
            text: 'Select discount first!',
            variant: 'danger',
          },
        })
        return
      }

      this.loading = true
      ExportAPI.exportDiscount(this.selectedDiscount)
        .then(() => {
          this.selectedDiscount = null
          this.loading = false
        })
    },
    onExportUsersByParams() {
      this.loading = true
      if (this.purchaseRange) {
        const date = this.purchaseRange.split(' to ')
        this.exportUsersForm.purchaseFrom = date[0].concat(' 00:00:00')
        this.exportUsersForm.purchaseTo = date[1].concat(' 23:59:59')
      }

      if (this.registeredRange) {
        const date1 = this.registeredRange.split(' to ')
        this.exportUsersForm.registeredFrom = date1[0].concat(' 00:00:00')
        this.exportUsersForm.registeredTo = date1[1].concat(' 23:59:59')
      }

      ExportAPI.exportUsersBySale(this.exportUsersForm).then(() => {
        this.loading = false
      })
    },
    onExportTradingHistory() {
      if (this.exportTradingHistoryForm.range !== null) {
        const date = this.exportTradingHistoryForm.range.split(' to ')
        this.exportTradingHistoryForm.from = date[0].concat(' 00:00:00')
        this.exportTradingHistoryForm.to = date[1].concat(' 23:59:59')
      }

      if (this.exportTradingHistoryForm.range !== null && this.exportTradingHistoryForm.user !== null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Unable to proceed',
            icon: 'WarningIcon',
            text: 'Select time range or user first!',
            variant: 'danger',
          },
        })
        return
      }

      this.loading = true
      ExportAPI.exportTradingHistory(this.exportTradingHistoryForm).then(() => {
        this.loading = false
      })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Unable to proceed',
              icon: 'WarningIcon',
              text: 'file is to large, select smaller range!',
              variant: 'danger',
            },
          })
          this.loading = false
        })
    },
    onExportStatsByProducts() {
      if (this.salesByProductRange) {
        const date = this.salesByProductRange.split(' to ')
        this.salesByProductForm.from = date[0].concat(' 00:00:00')
        this.salesByProductForm.to = date[1].concat(' 23:59:59')
      } else {
        return
      }

      this.loading = true

      ExportAPI.exportSalesByProducts(this.salesByProductForm).then(() => {
        this.loading = false
      })
    },
    onExportTopPayouts() {
      if (this.exportTopPayoutsForm.range) {
        const date = this.exportTopPayoutsForm.range.split(' to ')
        this.exportTopPayoutsForm.from = date[0].concat(' 00:00:00')
        this.exportTopPayoutsForm.to = date[1].concat(' 23:59:59')
      }

      this.loading = true

      ExportAPI.exportTopPayouts(this.exportTopPayoutsForm).then(() => {
        this.loading = false
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker";
@import '@core/scss/vue/libs/vue-select.scss';
</style>
